<template>
    <div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url('../../auth-bg.jpg') no-repeat center center; background-color: #F0F5F9">
        <div class="auth-box" style="max-width: 800px;">
            <div id="loginform">
                <div class="logo">
                    <span class="db"><img src="../../assets/img/logo-icon.png" class=" mb-3" alt="logo" style="width: 45px;"/></span>

                    <h5 class="font-medium ">{{ $t("apps.app_name") }}</h5>
                    <p>
                        {{ $t("auth.register_as") }}
                        <span class="text-primary">
                            {{ $t("global.admin") }}
                        </span>
                    </p>
                </div>
                <!-- Form -->
                <div class="row">
                    <div class="col-12">
                        <form class="form-horizontal m-t-20" id="loginform" method="POST" v-on:submit.prevent="signUp">
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                                        </div>
                                        <input type="text" class="form-control form-control-lg" :placeholder="$t('global.name') + '*'" v-model="form.name" required aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i class="ti-pencil-alt2 "></i></span>
                                        </div>
                                        <input type="text" class="form-control form-control-lg" :placeholder="$t('global.username') + '*'" v-model="form.username" required aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i class="ti-email"></i></span>
                                        </div>
                                        <input type="text" class="form-control form-control-lg" :placeholder="$t('global.email') + '*'" v-model="form.email" required aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i class="mdi mdi-cellphone-iphone"></i></span>
                                        </div>
                                        <input type="text" class="form-control form-control-lg" :placeholder="$t('global.whatsapp_number') + '*'" v-model="form.phone" required aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2"><i class="mdi mdi-key-minus"></i></span>
                                        </div>
                                        <input class="form-control form-control-lg" :placeholder="$t('auth.password') + '*'" :type="passwordFieldType" v-model="form.password" required aria-label="Password" aria-describedby="basic-addon1">
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i class="mdi mdi-keyboard"></i></span>
                                        </div>
                                        <input type="text" class="form-control form-control-lg" :placeholder="$t('global.referral_code')" v-model="form.referral" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="checkbox-theme-default custom-checkbox">
                                        <input v-model="form.trust" class="checkbox mr-2" type="checkbox" id='check-1' required>
                                        <label for="check-1">
                                            <span class="checkbox-text">{{ $t("auth.privacy_policy_agreement") }}*</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group text-center">
                                <div class="col-xs-12 p-b-20">
                                    <button class="btn btn-block btn-lg btn-info d-flex justify-content-center" style="gap: .5rem" :disabled="isLoading">
                                        <div v-if="isLoading" class="spinner-border spinner" role="status"></div>
                                        <div>{{ isLoading ? $t("auth.loading") : 'Daftar' }}</div>
                                    </button>
                                </div>
                            </div>
                            
                            <div class="form-group m-b-0 m-t-10">
                                <div class="col-sm-12 text-center">
                                    <!-- Don't have an account? <a href="authentication-register1.html" class="text-info m-l-5"><b>Sign Up</b></a> -->
                                    {{ $t("auth.already_have_account") }}
                                    <router-link to="/login" class="color-primary">
                                        {{ $t("auth.sign_in_now") }}
                                    </router-link>
                                    <!-- <br />
                                    {{ $t("auth.need_verify_account") }}
                                    <router-link to="/verify" class="color-primary">
                                        {{ $t("auth.verify_now") }}
                                    </router-link> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div id="recoverform">
                <div class="logo">
                    <span class="db"><img src="../../assets/images/logo-icon.png" alt="logo" /></span>
                    <h5 class="font-medium m-b-20">Recover Password</h5>
                    <span>Enter your Email and instructions will be sent to you!</span>
                </div>
                <div class="row m-t-20">
                    <!-- Form -->
                    <form class="col-12" action="index.html">
                        <!-- email -->
                        <div class="form-group row">
                            <div class="col-12">
                                <input class="form-control form-control-lg" type="email" required="" placeholder="Username">
                            </div>
                        </div>
                        <!-- pwd -->
                        <div class="row m-t-20">
                            <div class="col-12">
                                <button class="btn btn-block btn-lg btn-danger" type="submit" name="action">Reset</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                form: {
                    name: "",
                    username: "",
                    email: "",
                    password: "",
                    phone: "",
                    referral: "",
                    trust: ""
                },
                passwordFieldType: "password",
                isLoading: false,
            };
        },
        mounted() {},
        methods: {
            async signUp() {
                this.isLoading = true;

                try {
                    console.log(this.form)
                    let register = await this.$store.dispatch("auth_register", this.form);
                    this.$toast.success(register.data.message);
                    await this.$router.push("/verify?phone=" + this.form.phone);
                    // await this.$root.$emit("login", true);
                    // //this.$toast.success("Login success");
                    // await this.$router.push("/dashboard");
                    // window.location.reload()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.isLoading = false;
            },
            togglePassword(){
                if(this.passwordFieldType == "password"){
                    this.passwordFieldType = "text"
                }else{
                    this.passwordFieldType = "password"
                }
            }
        },
    };
</script>

<style scoped>
    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

    .btn {
        line-height: 1rem !important;
    }
</style>